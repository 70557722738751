<template>
  <div>
    <SchoolAwayDetailTableControl class="mb-5" />
    <AppPageCard>
      <template #card-title> List of Away Types </template>
      <template #card-toolbar>
        <b-button
          class="btn btn-icon btn-light btn-hover-primary mr-3"
          v-on:click="refresh"
        >
          <span class="svg-icon svg-icon-2x svg-icon-primary">
            <inline-svg src="/media/svg/icons/Media/Repeat.svg" />
          </span>
        </b-button>
        <b-button
          variant="light-primary"
          class="font-weight-bolder"
          v-b-toggle.school-away-detail-list-sidebar
        >
          {{ $t("TEACHER.BUTTON") }}
        </b-button>
      </template>

      <SchoolAwayDetailPageTable :reload="reload"></SchoolAwayDetailPageTable>

      <b-sidebar
        ref="school-away-detail-list-sidebar-ref"
        id="school-away-detail-list-sidebar"
        backdrop
        right
        lazy
        :title="$t('USERFORM.CREATE')"
        sidebar-class="offcanvas"
        header-class="offcanvas-header d-flex mt-5 ml-5 mr-5"
        bg-variant="white"
      >
        <template v-slot:header-close>
          <button
            class="btn btn-sm btn-icon btn-light-secondary btn-hover-danger"
          >
            <i class="ki ki-close icon-xs"></i>
          </button>
        </template>
        <div class="px-10 mt-5">
          <SchoolAwayDetailCreateForm
            v-slot:default="{}"
            title="Away Details"
            v-on:success="closeSideBar"
          ></SchoolAwayDetailCreateForm>
        </div>
      </b-sidebar>
    </AppPageCard>
  </div>
</template>

<script>
import AppPageCard from "@/modules/core/components/shared/card/AppPageCard";
import SchoolAwayDetailCreateForm from "@/modules/school/components/management/away/detail/SchoolAwayDetailCreateForm";
import SchoolAwayDetailPageTable from "@/modules/school/components/management/away/detail/SchoolAwayDetailPageTable";
import SchoolAwayDetailTableControl from "@/modules/school/components/management/away/detail/control/SchoolAwayDetailTableControl";
export default {
  name: "SchoolAwayDetailPage",
  components: {
    SchoolAwayDetailTableControl,
    SchoolAwayDetailPageTable,
    SchoolAwayDetailCreateForm,
    AppPageCard,
  },
  data() {
    return {
      reload: false,
    };
  },

  methods: {
    closeSideBar() {
      const sideBar = this.$refs["school-away-detail-list-sidebar-ref"];
      sideBar.hide();
      this.reload = !this.reload;
    },
    refresh() {
      this.reload = !this.reload;
    },
  },
};
</script>

<style scoped></style>
