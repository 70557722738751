<template>
  <CommonEmployeeDetailsModal title="Manage Away Type">
    <SchoolAwayDetailEditForm
      :is-busy="isBusy"
      :form-set="selectedAway"
      @handle-submit="handleSubmit"
    ></SchoolAwayDetailEditForm>
  </CommonEmployeeDetailsModal>
</template>

<script>
import CommonEmployeeDetailsModal from "@/modules/school/components/shared/employee/modal/CommonEmployeeDetailsModal";
import SchoolAwayDetailEditForm from "@/modules/school/components/management/away/detail/SchoolAwayDetailEditForm";
export default {
  name: "SchoolAwayDetailModal",
  components: { SchoolAwayDetailEditForm, CommonEmployeeDetailsModal },
  props: {
    title: {
      String,
    },
    selectedAway: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isBusy: false,
    };
  },
  mounted() {
    console.log("Mounted", this.selectedAway);
  },
  methods: {
    /**
     *
     * @param form
     */
    handleSubmit(form) {
      console.log(form);

      const payload = {
        ...form,
        id: this.selectedAway.id,
      };

      this.isBusy = true;
      this.$store
        .dispatch("updateSchoolAwayDetails", payload)
        .then((res) => {
          console.log(res);
          this.$bvModal.msgBoxOk("Success!!");
        })
        .finally(() => {
          this.isBusy = false;
          this.$emit("detail-updated");
        });
    },
  },
};
</script>

<style scoped></style>
