<template>
  <div>
    <b-table
      ref="school-away-details-page-dt"
      id="school-away-details-page-dt"
      responsive="sm"
      :fields="fields"
      :items="items"
      table-class="table-head-custom table-vertical-center mt-5"
      show-empty
      :busy="isBusy"
    >
      <!-- A custom formatted header cell for field 'name' -->
      <template v-slot:head(index)="data">
        <span class="text-dark-75">{{ data.label.toUpperCase() }}</span>
      </template>

      <!-- A virtual column -->
      <template v-slot:cell(index)="data">
        <span class="font-weight-bolder">{{ data.index + 1 }}</span>
      </template>

      <template v-slot:cell(name)="data">
        <div class="d-flex flex-column">
          <span class="font-weight-bolder text-h6">
            {{ data.item.name }}
          </span>
        </div>
      </template>

      <template v-slot:cell(abbreviation)="data">
        <div class="d-flex flex-column">
          <span class="font-weight-bolder text-h6">
            {{ data.item.abbreviation }}
          </span>
        </div>
      </template>

      <template v-slot:cell(actions)="data">
        <div class="d-flex flex-row justify-content-left">
          <!-- begin::: edit-details button -->
          <button
            class="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
            v-on:click="showAwayDetails(data)"
          >
            <span class="svg-icon svg-icon-primary menu-icon">
              <inline-svg src="/media/svg/icons/Communication/Write.svg" />
            </span>
          </button>
          <!-- end::: edit-details button -->

          <button
            class="btn btn-icon btn-light btn-hover-danger btn-sm mr-3"
            v-on:click="deleteSchoolAway(data.item)"
          >
            <span class="svg-icon svg-icon-gray menu-icon">
              <inline-svg src="/media/svg/icons/General/Trash.svg" />
            </span>
          </button>
          <!-- end::: delete-employee button -->
        </div>
      </template>

      <template #table-busy>
        <AppLoader #table-busy></AppLoader>
      </template>

      <template #empty="">
        <AppEmptyList
          text-top="No Away added"
          text-bottom="You have no created Away. To add new away for your company, please click on the CREATE button"
        ></AppEmptyList>
      </template>
    </b-table>

    <div class="d-flex justify-content-end" v-if="items.length">
      <b-pagination
        pills
        v-model="pagination.current_page"
        :total-rows="pagination.total"
        :per-page="pagination.per_page"
        aria-controls="leave-details-page-dt"
        @change="changePage"
      ></b-pagination>
    </div>

    <SchoolAwayDetailModal
      title="Manage Away"
      :selected-away="selectedAway"
      @detail-updated="fetch"
    ></SchoolAwayDetailModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AppEmptyList from "@/modules/core/components/empty/AppEmptyList";
import AppLoader from "@/modules/core/components/loaders/AppLoader";
import SchoolAwayDetailModal from "@/modules/school/components/management/away/detail/SchoolAwayDetailModal";

export default {
  name: "SchoolAwayDetailPageTable",
  components: { SchoolAwayDetailModal, AppLoader, AppEmptyList },
  props: ["reload"],
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      fields: [
        { key: "index", label: "#" },
        { key: "name", label: this.$t("JOB.EMPTYPE"), sortable: true },
        { key: "abbreviation", label: "Abbreviation" },
        { key: "description", label: this.$t("DETAILSTABLE.DESC") },
        { key: "actions", label: this.$t("DETAILSTABLE.ACT") },
      ],
      isBusy: false,
      selectedAway: {},
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.isBusy = true;
      return this.$store
        .dispatch("fetchSchoolAwayDetailList")
        .then((response) => {
          console.log("Received", response);
          // this.items = response.data.data;
          return response.data.data;
        })
        .finally(() => (this.isBusy = false));
    },

    refresh() {
      console.log("Reload Table!");
      this.fetch();
    },

    changePage(page) {
      console.log("Change page to ", page);
      this.$store.commit("setSchoolAwayDetailListPage", page);
      this.fetch();
    },

    deleteSchoolAway(row) {
      console.log("Selected: ", row.name);

      this.$bvModal
        .msgBoxConfirm(`This will delete ${row.name}`, {
          title: this.$t("ALERT.SURE"),
          centered: true,
          noCloseOnBackdrop: true,
          cancelVariant: "light",
        })
        .then((value) => {
          console.log("Hmmm", value);
          if (value) {
            return new Promise((resolve, reject) => {
              this.$store
                .dispatch("deleteSchoolAwayDetail", row.id)
                .then(() => {
                  this.$bvModal.msgBoxOk("Success!!", {
                    centered: true,
                  });
                  this.refresh();
                  resolve(value);
                })
                .catch(() => {
                  console.error("Some issues bro");
                  this.$bvModal.msgBoxOk("Failed!!", {
                    centered: true,
                    okVariant: "danger",
                  });
                  reject(value);
                });
            });
          }
        })
        .catch((err) => {
          console.error("Some issues bro", err);
        });
    },

    showAwayDetails(row) {
      console.log("Selected away ", row);

      this.selectedAway = row.item;

      this.$bvModal.show("common-employee-details-modal");
    },
  },
  watch: {
    reload() {
      this.fetch();
    },
  },
  computed: {
    ...mapGetters({
      items: "getSchoolAwayDetailList",
      pagination: "getSchoolAwayDetailListPagination",
      currentPage: "getSchoolAwayDetailListPage",
    }),
  },
};
</script>

<style scoped></style>
